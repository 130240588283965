<template>
  <div :class="$style.container">
    <!-- 面包屑导航 -->
    <Breadcrumb :breadList="breadList">
    </Breadcrumb>
    <a-form ref="form" class="al-form" layout="inline" :model="form" :label-col="{ style: 'width: 80px' }"
            :wrapper-col="{ style: 'width: 170px' }">
      <a-form-item label="券码:">
        <a-input v-model="form.couponCode" placeholder="请输入内容"/>
      </a-form-item>
      <a-form-item label="状态:">
         <a-select v-model="form.status" placeholder="请选择">
          <a-select-option :value="4">待绑定</a-select-option>
          <a-select-option :value="0">未使用</a-select-option>
          <a-select-option :value="1">已使用</a-select-option>
          <a-select-option :value="2">已失效</a-select-option>
          <a-select-option :value="3">已删除</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" :loading="isQueryLoading" @click="queryList">查询</a-button>
        <a-button @click="resetForm" style="margin-left:20px;">重置</a-button>
      </a-form-item>
    </a-form>
    <div v-if="couponStatus === '1'">
       <a-button type="primary"  @click="generateItem"> + 发放券码</a-button>
    </div>
    <br/>
    <a-table
     :data-source="list"
     :rowKey="(record,index)=>{return index}"
     :columns="columns"
     :pagination="pagination"
     :scroll="{ x: 1400, y: 580 }"
     bordered
    >
      <span slot="action1" slot-scope="scope">
        <div>{{scope.id}}</div>
        <div style="color:#1890ff;">{{ scope.couponName }}</div>
      </span>
       <span slot="action" slot-scope="scope">
        <a-popconfirm
          title="确定要作废吗？"
          ok-text="是"
          cancel-text="否"
          @confirm="confirmCancel(scope)"
          @cancel="cancel"
          v-if="scope.status === '0' || scope.itemStatus === '0'">
            <a href="#" style="margin-left: 10px;text-align: center">作废</a>
        </a-popconfirm>
      </span>
    </a-table>
     <!-- 编辑 评价审核设置 弹窗 -->
    <a-modal
      :title="generateItemModalTitle"
      :footer="null"
      :visible="generateItemVisible"
      @ok="handleOk"
      @cancel="handleCancel"
    >
        <GenerateItem  @ok="handleOk" @cancel="handleCancel" ref="generateItemModalRef"></GenerateItem>
    </a-modal>
  </div>
</template>

<script>
import Breadcrumb from '@/components/breadcrumb.vue'
import GenerateItem from './dialog/generateItem.vue'
export default {
  components: {
    Breadcrumb,
    GenerateItem
  },
  data () {
    return {
      breadList: [
        {
          name: '优惠券管理',
          url: '/coupon/list'
        },
        {
          name: '券码',
          url: ''
        }
      ],
      generateItemModalTitle: '发放券码',
      generateItemVisible: false, // 发放券码弹窗
      isQueryLoading: false,
      couponId: this.$route.params.couponId,
      couponStatus: this.$route.params.couponStatus,
      form: {
        couponCode: '',
        status: ''
      },
      columns: [
        { title: '优惠券名称', key: 'couponName', scopedSlots: { customRender: 'action1' } },
        { title: '券号', key: 'couponCode', dataIndex: 'couponCode' },
        { title: '状态', key: 'statusName', dataIndex: 'statusName' },
        { title: '领券人ID', key: 'userId', dataIndex: 'userId' },
        { title: '发放时间', key: 'gmtCreate', dataIndex: 'gmtCreate' },
        { title: '领取时间', key: 'gmtReceive', dataIndex: 'gmtReceive' },
        { title: '更新时间', key: 'gmtModified', dataIndex: 'gmtModified' },
        { title: '操作', key: 'operation', fixed: 'right', width: 100, scopedSlots: { customRender: 'action' } }
      ],
      pagination: {
        total: 0,
        current: 1,
        pageSize: this.$enums.Pagination.pageSize,
        showLessItems: false,
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: this.$enums.Pagination.pageSizes,
        showTotal: (total, range) => `共 ${total} 条记录 第 ${range[0]}-${range[1]} 条数据`,
        onChange: pageNo => this.pageNoChange(pageNo),
        onShowSizeChange: (pageNo, pageSize) => this.pageSizeChange(pageNo, pageSize)
      },
      total: 0,
      list: []
    }
  },
  created () {
    this.couponId = this.$route.params.couponId
    this.couponStatus = this.$route.params.couponStatus
    this.reloadList()
  },
  methods: {
    cancel (values) {
      console.log(values)
    },
    // 是否确认作废
    confirmCancel (record) {
      this.couponCancel(record)
    },
    async couponCancel (record) {
      const res = await this.$store.dispatch('http', {
        api: 'couponCancel',
        query: {
          couponId: record.couponId,
          couponCode: record.couponCode
        }
      })
      if (res) {
        this.$store.dispatch('showToast', {
          type: 'success',
          message: '操作成功'
        })
        this.loadList()
      }
    },
    // 重置表单
    resetForm (formName) {
      this.form.status = ''
      this.form.couponCode = ''
      this.queryList()
    },
    // 发放券码
    generateItem () {
      this.generateItemModalTitle = '发放券码'
      this.generateItemVisible = true
      this.$nextTick(() => {
        this.$refs.generateItemModalRef.init(this.couponId)
      })
    },
    handleOk (e) {
      setTimeout(() => {
        this.generateItemVisible = false
      })
      this.queryList()
    },
    handleCancel (e) {
      this.generateItemVisible = false
    },
    // 查询列表
    queryList () {
      this.pagination.current = 1
      this.isQueryLoading = true
      this.reloadList(() => {
        this.isQueryLoading = false
      })
    },
    // 重新加载列表（参数初始化）
    reloadList (callback = () => {
    }) {
      this.loadList(callback)
    },
    // 加载列表
    async loadList (callback = () => {
    }) {
      this.isTableLoading = true
      const params = Object.assign({
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize
      }, this.form)
      const { total, records } = await this.$store.dispatch('http', {
        api: 'queryCouponItems',
        query: {
          couponId: this.couponId
        },
        params,
        complete: () => {
          this.isTableLoading = false
          callback()
        }
      })
      this.pagination.total = total
      if (records) {
        records.forEach(item => {
          item.isLoading = false
        })
      }
      this.list = records
    },
    // 页码切换
    pageNoChange (pageNo) {
      this.pagination.current = pageNo
      this.loadList()
    },
    // 每页显示条数切换
    pageSizeChange (pageNo, pageSize) {
      this.pagination.pageSize = pageSize
      this.pagination.current = pageNo
      this.reloadList()
    }
  }
}
</script>

<style lang="scss" module>
  .container {
    .form__ct {
      padding: 10px 0;
    }

    .form__items {
      padding-top: 6px;
    }
  }
</style>
