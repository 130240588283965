<template>
  <a-form-model
    ref="form"
    :rules="rules"
    :label-col="{ span: 5 }"
    :wrapper-col="{ span: 19 }"
    :model="form"
  >
    <a-form-model-item label="优惠券名称:">
      {{couponName}}
    </a-form-model-item>
    <a-form-model-item label="剩余优惠券">
      {{residue}}
    </a-form-model-item>
    <a-form-model-item label="下载数量" prop="qty">
       <a-input-number  v-model="form.qty" :min = 1 style="width: 280px;" placeholder="单次下载少于5000张"/>
    </a-form-model-item>
    <a-form-model-item
      :colon="false"
      label=" "
      :label-col="{ span: 5 }"
      :wrapper-col="{ span: 8 }"
    >
      <a-space>
        <a-button @click="cancel"> 取消 </a-button>
        <a-button @click="determine" type="primary">确认</a-button>
      </a-space>
    </a-form-model-item>

    <br />
  </a-form-model>
</template>

<script>
import { getQueryString } from '@/utils'
import store from '@/store'
export default {
  data () {
    const validQty = (rule, value, callback) => {
      if (value) {
        const re = /^[0-9]*[1-9][0-9]*$/
        const rsCheck = re.test(value)
        if (!rsCheck) {
          return callback(new Error('请输入整数'))
        }
        if (value > 5000) {
          return callback(new Error('单次下载少于5000张'))
        }
        if (this.total >= 0 && value > this.residue) {
          return callback(new Error('下载数量已超过剩余数量'))
        }
      }
      callback()
    }
    return {
      btCount: 0,
      isDetermineLoading: false, // 是否显示确定loading
      couponId: '',
      couponName: '',
      residue: '',
      total: '',
      form: {
        qty: ''
      },
      rules: {
        qty: [
          { required: true, message: '下载数量不能为空', trigger: 'change' },
          { validator: validQty, trigger: 'change' }
        ]
      }
    }
  },
  created () {},
  mounted () {},
  computed: {},
  methods: {
    resetForm () {
      this.form = {
        qty: ''
      }
    },
    cancel () {
      this.resetForm()
      this.$emit('cancel')
    },
    async init (couponId) {
      this.couponId = couponId
      this.getCouponInfo(couponId)
    },
    async getCouponInfo (couponId) {
      const res = await this.$store.dispatch('http', {
        api: 'getCouponInfo',
        query: {
          id: couponId
        }
      })
      if (res) {
        this.couponName = res.name
        this.residue = res.residue
        this.total = res.total
        if (this.total === -1) {
          this.residue = '无限'
        }
      }
    },
    async exportCouponCode () {
      let that = this
      const tokenType = getQueryString('tokenType') || store.state.user.tokenType
      const tokenValue = getQueryString('tokenValue') || store.state.user.tokenValue
      const currentTenantId = getQueryString('currentTenantId') || store.state.user.currentTenantId
      axios({
        method: 'post',
        responseType: 'blob',
        url:
          process.env.VUE_APP_GATEWAY + '/promotion/manage/coupon/normal/' + that.couponId + '/generate/item/' + this.form.qty,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: tokenType + ' ' + tokenValue,
          tenantId: currentTenantId
        }
      })
        .then(function (res) {
          if (res.data.type.includes('application/json')) {
            const reader = new FileReader()
            reader.onload = function (event) {
              console.log('event: ', event)
              const message = JSON.parse(event.target.result).msg
              that.$store.dispatch('showToast', {
                type: 'error',
                message: message
              })
            }
            reader.readAsText(res.data)
          } else {
            that.$store.dispatch('showToast', { message: '操作成功~' })
            const blob = new Blob([res.data], {
              type: 'application/vnd.ms-excel'
            })
            const fileName = res.headers['content-disposition']
              .split(';')[1]
              .split('filename=')[1]
            const eleLink = document.createElement('a')
            eleLink.download = decodeURIComponent(fileName)
            eleLink.style.display = 'none'
            eleLink.href = URL.createObjectURL(blob)
            // 触发点击
            document.body.appendChild(eleLink)
            eleLink.click()
            document.body.removeChild(eleLink)
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    // 提交保存
    async determine (e) {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          this.exportCouponCode()
          this.resetForm()
          this.$emit('ok')
        }
      })
      // this.goComfirm()
    },
    async goComfirm (e) {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          let api = 'generateItemAdd'
          await this.$store.dispatch('http', {
            api,
            query: {
              couponId: this.couponId,
              qty: this.form.qty
            },
            complete: () => {
              this.isDetermineLoading = false
            }
          })
          this.$store.dispatch('showToast', {
            message: '下载券码成功'
          })
          this.$emit('ok')
        }
      })
    }
  }
}
</script>

<style lang="scss" module>
.container {
  width: auto;
}
</style>
